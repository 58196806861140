import {Typography} from '@mui/material';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';

import phoneArrow from '../../assets/phone-banner.png';
import logo from '../../assets/sf_logo.png';
import css from './index.module.scss';
import SingleAddressPanel from './SingleAddressPanel';

export default function LoginLayout(props) {
  const [shouldTransitAnimations, setShouldTransitAnimations] = useState(false);

  useEffect(()=>{
    setTimeout(()=>{
      setShouldTransitAnimations(true);
    }, 150);
  }, []);

  return (
    <div className={css.LoginLayout}>
      <div className={classNames(css.section, css.sectionMenu)}>
        <div className={classNames(css.sectionContent)}>
          <img className={css.logo} src={logo} alt="Stapelfinancieringen" />
          <a href="https://www.stapelfinancieringen.nl/contact" className={classNames(css.phoneArrow, {[css.phoneArrowOpened]: shouldTransitAnimations})} >
            <img src={phoneArrow} alt="" />
          </a>
        </div>
      </div>
      <div className={classNames(css.section, css.sectionPayload)}>
        <div className={css.transparentColored} />
        <div className={classNames(css.sectionContent)}>
          <div className={css.shiftLeft}>
            {props.children}
          </div>
        </div>
      </div>
      <div className={classNames(css.section, css.sectionWhite)}>
        <div className={classNames(css.sectionContent)}>
        </div>
      </div>
      <div className={classNames(css.section, css.sectionAddresses)}>
        <div className={classNames(css.sectionContent)}>
          <div className={css.addressesPanel}>
            <SingleAddressPanel
              title="CONTACT TILBURG"
              items={[
                'Stapelfinancieringen BV',
                'Burgemeester Brokxlaan 12',
                '5041 SB Tilburg',
                '+31 (0)13 850 01 21',
                {text: 'info@stapelfinancieringen.nl', href: 'mailto:info@stapelfinancieringen.nl'},
              ]}
            />
            <SingleAddressPanel
              title="CONTACT ROTTERDAM"
              items={[
                'Marconistraat 16',
                '(Rotterdam science tower)',
                '3029 AK Rotterdam',
                '+31 (0)10 808 12 24',
                {text: 'rotterdam@stapelfinancieringen.nl', href: 'mailto:rotterdam@stapelfinancieringen.nl'},
              ]}
            />
            <SingleAddressPanel
              title="CONTACT EINDHOVEN"
              items={[
                'Kanstanjelaan 400',
                'Microlab-Strijp S',
                '5616 LZ Eindhoven',
                '+31 (0)40-2094217',
                {text: 'eindhoven@stapelfinancieringen.nl', href: 'mailto:eindhoven@stapelfinancieringen.nl'},
              ]}
            />
            <SingleAddressPanel
              title="STAPELFINANCIERINGEN"
              items={[
                {text: 'Over Stapelfinancieringen', href: 'https://www.stapelfinancieringen.nl/over-ons/'},
                {text: 'Direct contact', href: 'https://www.stapelfinancieringen.nl/contact/'},
                {text: 'Disclaimers & voorwaarden', href: 'https://www.stapelfinancieringen.nl/disclaimer/'},
                {text: 'Privacy & Cookies', href: 'https://www.stapelfinancieringen.nl/privacy-verklaring/'},
              ]}
            />
          </div>
          <div className="copyright-panel">

          </div>
        </div>
      </div>
      <div className={classNames(css.section, css.sectionFooter)}>
        <div className={classNames(css.sectionContent)}>
          <Typography variant="p" fontSize={16} fontWeight={500} color="#FFFFFF">© 2022 Stapelfinancieringen</Typography>
          <div className={css.softnautsSign}>
            <Typography variant="mediumText" color="#FFFFFF" >System by</Typography>
            <Typography variant="mediumText" color="#FFFFFF" sx={{marginLeft: '3px'}}>
              <a href="https://softnauts.com/" className={css.link}>
                <span className={css.linkText}>Softnauts</span>
              </a>
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
}

LoginLayout.propTypes = {
  children: PropTypes.any.isRequired,
};
