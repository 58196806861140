import Api from '../../../helpers/api/Api';

export default function patchCompany(patchData, originalData) {
  return new Promise(function(resolve, reject) {
    const newPatchObject = {};

    setIfValueDiffers(
        patchData.name,
        originalData.name,
        'name',
        newPatchObject,
    );
    setIfValueDiffers(
      patchData.handler ? `/api/users/${patchData.handler}` : null,
      `/api/users/${originalData.handler}`,
      'handler',
      newPatchObject,
    );
    setIfValueDiffers(
        `/api/company_legal_forms/${patchData.legalForm}`,
        `/api/company_legal_forms/${originalData.legalForm}`,
        'legalForm',
        newPatchObject,
    );
    setIfValueDiffers(
        patchData.type,
        originalData.type,
        'type',
        newPatchObject,
    );

    if (
      patchData.street !== originalData.street ||
      patchData.city !== originalData.city ||
      patchData.postal !== originalData.postal ||
      patchData.country !== originalData.country
    ) {
      newPatchObject.companyAddresses = [{
        line1: patchData.street,
        city: patchData.city,
        postal: patchData.postal,
        country: patchData.country,
      }];
    }

    if (Array.isArray(patchData.tags)) {
      newPatchObject.tags = [];
      patchData.tags.forEach((item) => {
        newPatchObject.tags.push(item['@id']);
      });
    }

    newPatchObject.securityContexts = patchData.securityContextsIds.map((id)=>`/api/security_contexts/${id}`);

    Api.patch(
        `/companies/${patchData.id}`,
        newPatchObject,
    ).then((data) => {
      resolve(data);
    }).catch((error) => {
      reject(error);
    });
  });
}

function setIfValueDiffers(sourceValue, originalValue, field, target) {
  if (sourceValue !== originalValue) {
    target[field] = sourceValue;
  }
}
